<template>
    <Thumb v-if="product.id" :product="product" :icon="true"  :effect="effect" :short="true" :small="true" @product-clicked="$emit('product-clicked')"></Thumb>
</template>

<script>

import Thumb from './ProductThumb.vue';

export default {
    components: { Thumb },
    emits: ['product-clicked'],
    props: {
        product: {
            required: true
        },
        effect: {
            type: Boolean,
            default: true
        },
    }
}
</script>
